body{
  min-height: 100vh;
  text-align: center;
  margin: 0;
  padding: 0px;
  background: linear-gradient(47deg, rgba(10,0,255,1) 0%, rgba(255,0,0,1) 100%);
  background-size: cover;
  background-repeat: no-repeat;
}

.hover-grow{
  transition: all .1s ease-in-out;
  cursor: pointer;
}

.hover-grow:hover{
  transform: scale(1.03);
}

.spel{
  width: 90%;
  max-width: 600px;
  background-color: white;
  box-shadow: 0 0 10px gray;
  color: black;
  text-align: center;
  margin: auto;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.steenpapierbak{
  width: 90%;
  max-width: 600px;
  background-color: white;
  box-shadow: 0 0 10px gray;
  color: black;
  text-align: center;
  margin: auto;
  border-radius: 5px; 
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.kaart{
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 30px;
  box-shadow: 0 0 10px gray;
  width: 90%;
  max-width: 600px;
}

.footer{
  margin-top: 30vh;
  box-shadow: 0 0 10px gray;
  margin-bottom: 0;
  height: 150px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer img{
  height: 120px;
}

._space3d .defaultFace span {
  background-color: #6e0101!important;
}

._space3d .defaultFace div{
  background-color: #003678!important;
}